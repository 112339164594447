import { render, staticRenderFns } from "./rich-text.vue?vue&type=template&id=bf2d6318&scoped=true"
import script from "./rich-text.vue?vue&type=script&lang=js"
export * from "./rich-text.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf2d6318",
  null
  
)

export default component.exports