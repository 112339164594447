<!-- Localized -->
<template>
    <div class="flex items-center">
        <div v-html="getText"></div>
    </div>
</template>

<script>
export default {
    props: {
        fieldData: {
            type: Object,
            require: true,
        },
    },
    computed: {
        getText() {
            return this.fieldData.placeholder || "";
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
